<template>
  <div>
    <div class="text-center">
      <img src="../assets/images/img2.png" class="img2" />
    </div>
    <div class="size-58 text-bold text-center mt-120 mb-110" v-animate-onscroll.repeat="'animate__animated animate__zoomIn'">
      {{ $t("关于我们") }}
    </div>

    <div class="main-padding about">
      <div class="img3" v-animate-onscroll.repeat="'animate__animated animate__backInLeft'">
        <img
          src="../assets/images/5.jpg"
          class="img3-1 flex-shrink"
        />
        <img
          src="../assets/images/6.png"
          class="img3-2 flex-shrink"
          @click="videoShow = true"
          v-if="!videoShow"
        />
        <video
          src="https://www.613protocol.com/upload/ss/2025-02-13%2013.36.47.mp4"
          class="video"
          autoplay
          controls
          v-if="videoShow"
        ></video>
      </div>
      <div class="size-18 line-height-36 abount-content" v-animate-onscroll.repeat="'animate__animated animate__backInRight'">
        {{ $t("协议是...") }}<br />
        {{ $t("以跨链...") }}<br />
        {{ $t("打破了...") }}<br />
        {{ $t("全面连...") }}<br />
        {{ $t("聚合挖...") }}<br />
        {{ $t("让用户...") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
  data() {
    return {
      videoShow: false,
    };
  },
};
</script>

<style lang="scss">
.img3 {
  max-width: 80vw;
  width: 4.78rem;
  transition: all 0.3s;
  position: relative;
  margin-right: 0.7rem;
  .img3-1 {
    width: 100%;
    height: 100%;
  }
  .img3-2 {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    cursor: pointer;
  }
  .video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    object-fit: cover;
    cursor: pointer;
  }
}
.abount-content {
  transition: all 0.3s;
  &:hover {
    border-radius: 0.1rem;
    padding-left: 0.1rem;
    box-shadow: 0 0 5px #ffffff;
  }
}
@media (min-width: 768px) {
  .about {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media (max-width: 768px) {
  .about {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .img3{
    margin: 0 auto;
  }
}
</style>